/* AuditTable.css */
.data-grid-theme {
    background-color: #285c9a;
    color: #ffffff;
}

.data-grid-theme .MuiDataGrid-root {
    border: none;
}

/* Increase specificity by targeting the DataGrid's internal classes directly */
.data-grid-theme .MuiDataGrid-root .MuiDataGrid-main::-webkit-scrollbar,
.data-grid-theme .MuiDataGrid-root .MuiDataGrid-window::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.data-grid-theme .MuiDataGrid-root .MuiDataGrid-main,
.data-grid-theme .MuiDataGrid-root .MuiDataGrid-window {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.data-grid-theme .MuiDataGrid-cell,
.data-grid-theme .MuiDataGrid-columnHeader {
    border-bottom: 1px solid #394a59;
}

.data-grid-theme .MuiDataGrid-columnHeader {
    background-color: #1a3d66;
    color: #FFFFFF;
    font-weight: bold;
}

.data-grid-theme .MuiDataGrid-cell {
    background-color: #285c9a;
    color: #ffffff;
}

.data-grid-theme .MuiDataGrid-row:nth-of-type(even) {
    background-color: #285c9a;
}

.data-grid-theme .MuiDataGrid-footerContainer {
    background-color: #1a3d66;
    color: #a0aec0;
}

.data-grid-theme .MuiDataGrid-toolbarContainer {
    background-color: #1a3d66;
    color: #a0aec0;
}

.data-grid-theme .MuiButtonBase-root {
    color: #ffffff;
}

.data-grid-theme .MuiDataGrid-iconSeparator {
    display: none;
}

.data-grid-theme .MuiDataGrid-sortIcon {
    color: #a0aec0;
}

.status-circle {
    position: relative;
    display: inline-block;
    align-items: center;
    width: 16px; /* Adjust the size as needed */
    height: 16px; /* Adjust the size as needed */
    border-radius: 50%;
    margin-right: 8px;
}

.status-circle::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px; /* Adjust the size as needed */
    height: 8px; /* Adjust the size as needed */
    border-radius: 50%;
    background-color: #1e2a38; /* Match the background color */
}

.status-select-cursor {
    cursor: pointer;
}

.status-circle.none {
    background-color: grey;
}

.status-circle.in-progress {
    background-color: yellow;
}

.status-circle.completed {
    background-color: green;
}

.status-circle.canceled {
    background-color: red;
}

