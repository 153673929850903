/* src/assets/css/Sidebar.css */
.no-scrollbar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.customSidebar .MuiListItemIcon-root {
    min-width: 40px; /* Adjust icon container width */
}

.customSidebar .MuiListItemText-primary {
    font-size: 0.875rem; /* Adjust text size if necessary */
}

.customSidebar {
    background-color: #243447; /* Match the navbar background color */
    color: white; /* Match the navbar text color */
}

.css-cveggr-MuiListItemIcon-root {
    min-width: 30px !important;
}

/* Adjust the logo and menu icon sizes for the minimized sidebar */